.hww {
    padding: 8rem 0 0 0;
    background: #313131;

    &__title {
        text-align: right;
        font-size: 28px;
        color: #fff;
        letter-spacing: 2px;
        font-weight: 400;
        margin: 0 24rem 70px 0;

        @media screen and (max-width: 1278px) {
            margin: 0 4rem 70px 30px;
        }

        @media screen and (max-width: 990px) {
            text-align: center;
            br {
                display: none;
            }
        }

        @media screen and (max-width: 358px) {
            font-size: 20px;
            margin-bottom: 100px;
        }
    }

    &__imgBlock {
        display: flex;
        background: url(../../../images/HowWeWork/BackgroundTitle.webp) no-repeat;
        background-position: center;
        background-size: cover;
        max-width: 2500px;
        min-height: 550px;

        .hww__colorBlock {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 550px;
            height: 550px;
            background-color: rgba(114, 88, 69, 0.85);
            padding: 40px;
            margin-left: 35px;

            @media screen and (max-width: 768px) {
                margin: 0 auto;
            }

            p {
                text-align: center;
                line-height: 35px;
                font-size: 22px;
                color: #fff;
                border: 5px solid #fff;
                padding: 26px 34px;

                @media screen and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 30px;
                    margin: 0 auto;
                }
            }
        }
    }
    
    // button {
    //     display: flex;
	//     justify-content: center;
	//     align-items: center;
    //     white-space: nowrap;
	//     outline: none;
    //     background-color: #CD853F;
	//     min-width: 100px;
	//     max-width: 200px;
	//     cursor: pointer;
	//     text-decoration: none;
    //     border: none;
	//     color: black;
    //     font-weight: bold;
    //     transition: 0.7s;
    //     font-size: 14px;
    //     padding: 16px 40px;
    //     margin: 0 auto;
        
    //     &:active {
    //         transform: scale(0.93);
    //         transition: 0.1s;
    //     }
    // }
}