@import "~slick-carousel/slick/slick-theme.scss";

.fd {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  background-color: #333333;
}

.title {
  margin-bottom: 20px;
}

.sliderContainer {
  width: 100%;
  max-width: 1850px;
  margin-top: 50px;

  @media screen and (max-width: 1900px) {
    padding: 0 44px;
    max-width: 1750px;
  }
}

div [class*="slick-slide"] img {
  max-width: 100%;
  height: auto;
}

div [class*="slick-list"] {
  max-height: 930px;
  border-radius: 4px;
  box-shadow: 0px 20px 30px -25px rgba(255,255,255,0.75);

  @media screen and (max-width: 1600px) {
    max-height: 750px !important;
  }

  @media screen and (max-width: 1366px) {
    max-height: 618px !important;
  }

  @media screen and (max-width: 1280px) {
    max-height: 570px !important;
  }

  @media screen and (max-width: 800px) {
    max-height: 450px !important;
  }
}

ul[class*="slick-dots"] li {
  button::before {
    content: "";
    width: 22px;
    height: 3px;
    background: white;
    border-radius: 2px;
  }
}

// button[class*="slick-arrow"] {
//   display: none !important;
// }
